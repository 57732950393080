import * as React from "react"
import { Link, graphql } from "gatsby"
import ReactPlayer from "react-player"

import Navbar from "../components/Navbar/Navbar"
import Footer from "../components/Footer/Footer"
import ArticleCard from "../components/ArticleCard/ArticleCard"
import Seo from "../components/Seo"

const Index = ({ data: { allWpNew, allWpPublicOutreachEvent } }) => {
  const latestNews = allWpNew.nodes
  const recentPublicOutreach = allWpPublicOutreachEvent.nodes

  return (
    <React.Fragment>
      <Seo />

      <Navbar />
      <main className="marginTopNavbar">
        <section className="hero">
          <div className="hero__image">
            {/* <StaticImage
              draggable="false"
              src="../images/Arco SMA20x1.jpg"
              layout="fullWidth"
              loading="eager"
              style={{ height: "100%" }}
              alt="Image hero ATG"
            /> */}

            {/* <iframe
              className="hero__image__video"
              src={
                "https://erachair.uc-biotech.pt/api/wp-content/uploads/2021/10/Advanced_Therapies_HQ_light.mp4"
              }
              title={"Advanced Therapies Group Presentation"}
              allow="autoplay"
              frameBorder="0"
              webkitallowfullscreen="true"
              mozallowfullscreen="true"
              allowFullScreen
            /> */}

            <ReactPlayer
              className="hero__image__video"
              url="https://erachair.uc-biotech.pt/api/wp-content/uploads/2021/10/Advanced_Therapies_HQ_light.mp4"
              playing
              muted
              controls
            />
          </div>
          <div className="blackline"></div>
          <div className="hero__header main-container">
            <div className="hero__header__title">
              <h1>Advanced Therapies Group</h1>
            </div>
            <div className="hero__header__description">
              <p>
                The research group aims at generating fundamental and
                translational knowledge in the intersection of biomaterials with
                stem cells.
              </p>
              <span>Scroll Down ↓</span>
            </div>
          </div>
        </section>

        <div className="main-container">
          <section className="latest-news">
            <h1 className="latest-news__title">Latest News</h1>
            <div className="latest-news__grid">
              {latestNews.map(news => {
                const title = news.NewGQL.title
                const image =
                  news.NewGQL.thumbnailImage.localFile.childImageSharp
                    .gatsbyImageData
                const date = news.date
                const uri = news.uri

                return (
                  <ArticleCard
                    key={uri}
                    title={title}
                    alt={title}
                    image={image}
                    date={date}
                    uri={uri}
                  />
                )
              })}
            </div>
            <div className="latest-news__link">
              <Link to="/news">More News</Link>
            </div>
          </section>
        </div>

        <div className="public-outreach-intro">
          <h1 className="public-outreach-intro__title">
            Latest from <br /> Public Outreach
          </h1>
        </div>

        <div className="main-container">
          <section className="latest-outreach">
            <div className="latest-outreach__grid">
              {recentPublicOutreach.map(publicOutreach => {
                const title = publicOutreach.PublicOutreachGQL.title
                const image =
                  publicOutreach.PublicOutreachGQL.thumbnailImage.localFile
                    .childImageSharp.gatsbyImageData
                const date = publicOutreach.date
                const uri = publicOutreach.uri

                return (
                  <ArticleCard
                    key={uri}
                    title={title}
                    alt={title}
                    image={image}
                    date={date}
                    uri={uri}
                  />
                )
              })}
            </div>

            <div className="latest-news__link">
              <Link to="/public-outreach">Check Public Outreach</Link>
            </div>
          </section>
        </div>
      </main>
      <Footer />
    </React.Fragment>
  )
}

export const recentPosts = graphql`
  {
    allWpNew(limit: 3, sort: { fields: date, order: DESC }) {
      nodes {
        uri
        date(formatString: "MMMM DD, YYYY")
        NewGQL {
          title
          thumbnailImage {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH, aspectRatio: 1.46)
              }
            }
          }
        }
      }
    }
    allWpPublicOutreachEvent(limit: 3, sort: { fields: date, order: DESC }) {
      nodes {
        uri
        date(formatString: "MMMM DD, YYYY")
        PublicOutreachGQL {
          title
          thumbnailImage {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH, aspectRatio: 1)
              }
            }
          }
        }
      }
    }
  }
`

export default Index
